import { useEffect, useState } from "react"
import "./App.css"
import LegadoLogo from "./images/LegadoLogo"
import ConfettiExplosion from "react-confetti-explosion"
import CountUp from "react-countup"
import { TMilestone, milestoneData } from "./milestone"

function App() {
  const [userCount, setUserCount] = useState(0)
  const [isExploding, setIsExploding] = useState(false)
  const [updatedData, setUpdatedData] = useState<TMilestone[]>(milestoneData)

  const base = process.env.REACT_APP_API

  useEffect(() => {
    function saveData(data: TMilestone[]) {
      if (data) {
        const dataString = JSON.stringify(data)
        localStorage.setItem("updatedData", dataString)
      }
    }

    const fetchLatestUserCount = async () => {
      try {
        const api = `${base}/api/User/getlatestcount`
        const response = await fetch(api, {
          method: "GET",
          mode: "cors",
          headers: {
            ApiKey: process.env.REACT_APP_LEGADO_API_KEY ?? "",
            "Content-Type": "application/json",
          },
        })

        if (response.ok) {
          const data = await response.json()
          setUserCount(data.allUserCount)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    async function displayUserCount() {
      await fetchLatestUserCount()

      const notPoped = updatedData.findIndex((item) => !item.popped)

      if (notPoped !== -1 && updatedData[notPoped].value <= userCount) {
        const newUpdatedData = [...updatedData]

        for (let i = 0; i <= notPoped; i++) {
          newUpdatedData[i].popped = true
        }

        setUpdatedData(newUpdatedData)
        saveData(newUpdatedData)
        if (newUpdatedData.length > 0) {
          setIsExploding(true)
        }
      }
    }

    displayUserCount()

    const intervalId = setInterval(displayUserCount, 900000) // 15 minutes
    return () => clearInterval(intervalId)
  }, [updatedData, userCount, base, setUpdatedData, setIsExploding])

  return (
    <div className="dashboard">
      <header className="logo">
        <LegadoLogo />
      </header>
      <div>
        <p>Live stats:</p>
        <div className="counter">
          <div className="number">
            {isExploding && (
              <ConfettiExplosion
                onComplete={() => {
                  setIsExploding(false)
                }}
              />
            )}
            <CountUp duration={5} end={userCount} preserveValue />
            {isExploding && (
              <ConfettiExplosion
                onComplete={() => {
                  setIsExploding(false)
                }}
              />
            )}
            <p>Users</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
