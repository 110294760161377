export type TMilestone = {
  value: number
  popped: boolean
}

export const milestoneData = [
  {
    value: 100,
    popped: false,
  },

  {
    value: 250,
    popped: false,
  },
  {
    value: 500,
    popped: false,
  },

  {
    value: 750,
    popped: false,
  },

  {
    value: 1000,
    popped: false,
  },
  {
    value: 10000,
    popped: false,
  },
  {
    value: 100000,
    popped: false,
  },
  {
    value: 250000,
    popped: false,
  },
  {
    value: 500000,
    popped: false,
  },
  {
    value: 1000000,
    popped: false,
  },
  {
    value: 2000000,
    popped: false,
  },
]
